
          @use "sass:math";
          @import "~@/assets/scss/variables.scss";
          @import "~@/assets/scss/mixins/misc.scss";
        

.breadcrumbs {
  display: flex;
  font-size: 16px;
  font-weight: 500;
  line-height: 22px;
  user-select: none;
  color: $color-black;

  &__item {
    cursor: default;
    position: relative;
    margin-right: 15px;
    padding-right: 15px;
    white-space: nowrap;

    &--link {
      color: $color-grayscale-60;
      text-decoration: unset;
      cursor: pointer;
    }

    &:last-child {
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 600px;
    }

    &:not(:last-child) {
      &:after {
        content: '';
        border: solid $color-black;
        border-width: 0 2px 2px 0;
        display: inline-block;
        padding: 2px;
        transform: translateY(-50%) rotate(-45deg);
        position: absolute;
        top: 50%;
        right: 1px;
      }

      @include for-size(phone-portrait-down) {
        display: none;
      }
    }
  }
}
